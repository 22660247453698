import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import RxIndicator from "../../Elements/RxIndicator"

import { generatePrice } from "../../Epharmacy/services/computations"
import { formatPrice } from "../../../services/general"
import styles from "../utils/search.module.scss"

const MobileSearchResults = ({
  searchResults,
  isExactMatch,
  onSelect,
  searchDispatch,
}) => {
  const sortedSearchResults = searchResults.sort((a, b) => {
    return a.closestMatch === "molecule" &&
      a.productTitle.includes("Value Brand") &&
      a.distance >= b.distance
      ? -1
      : 1
  })

  return (
    <section className={classNames(styles["mobile_search__results"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.length > 0 && !isExactMatch && (
          <li
            className={classNames(
              "has-text-primary has-text-weight-bold is-size-7"
            )}
          >
            <FontAwesomeIcon icon={faExclamationCircle} /> Here are the closest
            items we have to the search term you entered.
          </li>
        )}
        {sortedSearchResults.map((result) => (
          <li
            onClick={() => {
              if (onSelect) onSelect(result)
              searchDispatch({ type: "SET_SEARCH_QUERY", payload: "" })
              searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
            }}
          >
            <div className="is-flex has-text-weight-bold has-text-black">
              <div>
                {result.productTitle?.split("(")[0]}
                {result?.description ? (
                  <p className="help has-text-weight-normal is-italic">
                    {result.description}
                  </p>
                ) : null}
              </div>
              {result.rxRequired && <RxIndicator />}
            </div>

            <div className="is-size-7 is-flex is-justify-content-space-between">
              {result.brand}
              <div>
                {result.productTitle !== "Partner Request (MedGrocer)" &&
                result.productTitle !== "MedGrocer Wellness Patient"
                  ? ` Php ${formatPrice({
                      priceString: generatePrice(result),
                    })}`
                  : ""}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default MobileSearchResults
